/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/PagePage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PageQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "page", uri: $uri, siteId: $siteId) {
        ... on Craft_page_default_Entry {
          id
          uid
          siteId
          isPrivate: boolean0
          password: heading0
          crumbs: ancestors {
            uid
            uri
            title
          }
          # pageBuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          title
          url
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    isPrivate,
    password,
    //
    uid,
    crumbs,
    // pageBuilder
    pageBuilder,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;
  return {
    uid,
    isPrivate,
    password,
    pageBuilder: resolvePageBuilder(pageBuilder, crumbs, title),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      isPrivate,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const loadMapStyles = resolvedData.pageBuilder
    .map(b => b.type)
    .includes("openStudiosMap");
  return (
    <HeadTemplate {...rest} data={resolvedData} loadMapStyles={loadMapStyles} />
  );
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PageQuery"
    {...props}
  />
);

export default Page;

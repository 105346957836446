import React, { useState } from "react";
import loadable from "@loadable/component";
import { AnimatePresence, m } from "framer-motion";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const Subnav = loadable(() => import("../components/molecules/Subnav"));

const PagePage = ({ data }) => {
  const { uid, pageBuilder, meta, isPrivate, password } = data;
  const [auth, setAuth] = useState(false);
  const site = process.env.GATSBY_CRAFT_HANDLE;
  const showSubnav = site === "openStudios" || site === "watsonville";
  return (
    <PageContainer meta={meta} path={uid} flex={isPrivate && password && !auth}>
      <AnimatePresence mode="wait">
        {isPrivate && password && !auth && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex w-full flex-grow flex-col items-center justify-center gap-3 border-y-2 border-white bg-black pb-20 pt-12 text-white md:pb-28 md:pt-28"
          >
            <h1 className="mb-6 font-heading text-6xl uppercase">
              Password Protected
            </h1>
            <label
              htmlFor="password"
              className="flex w-full flex-col items-center justify-center gap-3"
            >
              <span>Enter Password</span>
              <input
                id="password"
                name="password"
                type="password"
                className="w-1/2 border-2 border-white bg-black p-2 text-white focus:border-green sm:w-1/3 lg:w-1/4"
                onChange={e =>
                  setAuth(() => {
                    if (e.target.value === password) {
                      return true;
                    }
                    return false;
                  })
                }
              />
            </label>
          </m.div>
        )}
        {(!isPrivate || !password || auth) && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-grow flex-col"
          >
            {showSubnav && (
              <Subnav site={site} className="relative lg:left-[1.65rem]" />
            )}
            <PageBuilder blocks={pageBuilder} />
          </m.div>
        )}
      </AnimatePresence>
    </PageContainer>
  );
};

export default PagePage;
